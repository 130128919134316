.sidebar-dark {
  background-color: $dark-bg;
  color: rgba($color: #ffffff, $alpha: 0.5);
}
.navbar-dark {
  color: rgba($color: #ffffff, $alpha: 0.5);
  background-color: $dark-bg;
  border-bottom-color: rgba(255, 255, 255, 0.1);
}
.sidebar-dark .nav-sidebar .nav-link:not(.disabled):hover,
.sidebar-light
  .card[class*='bg-']:not(.bg-light):not(.bg-white):not(.bg-transparent)
  .nav-sidebar
  .nav-link:not(.disabled):hover {
  color: rgba($color: #50b848, $alpha: 1);
  background-color: rgba($color: #000000, $alpha: 0.5);
}
.sidebar-dark .nav-sidebar > .nav-item-open > .nav-link:not(.disabled),
.sidebar-dark .nav-sidebar > .nav-item > .nav-link.active,
.sidebar-light
  .card[class*='bg-']:not(.bg-light):not(.bg-white):not(.bg-transparent)
  .nav-sidebar
  > .nav-item-open
  > .nav-link:not(.disabled),
.sidebar-light
  .card[class*='bg-']:not(.bg-light):not(.bg-white):not(.bg-transparent)
  .nav-sidebar
  > .nav-item
  > .nav-link.active {
  background-color: rgba($color: #000000, $alpha: 0.5);
  color: rgba($color: #50b848, $alpha: 1);
}
