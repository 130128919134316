@font-face {
  font-family: 'ProximaNova';
  src: url('mark_simonson_-_proxima_nova_bold_it.eot');
  src: url('mark_simonson_-_proxima_nova_bold_it.eot?#iefix')
      format('embedded-opentype'),
    url('mark_simonson_-_proxima_nova_bold_it.woff2') format('woff2'),
    url('mark_simonson_-_proxima_nova_bold_it.woff') format('woff'),
    url('mark_simonson_-_proxima_nova_bold_it.svg#proxima_novabold_italic')
      format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('mark_simonson_-_proxima_nova_bold.eot');
  src: url('mark_simonson_-_proxima_nova_bold.eot?#iefix')
      format('embedded-opentype'),
    url('mark_simonson_-_proxima_nova_bold.woff2') format('woff2'),
    url('mark_simonson_-_proxima_nova_bold.woff') format('woff'),
    url('mark_simonson_-_proxima_nova_bold.svg#proxima_novabold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('mark_simonson_-_proxima_nova_it.eot');
  src: url('mark_simonson_-_proxima_nova_it.eot?#iefix')
      format('embedded-opentype'),
    url('mark_simonson_-_proxima_nova_it.woff2') format('woff2'),
    url('mark_simonson_-_proxima_nova_it.woff') format('woff'),
    url('mark_simonson_-_proxima_nova_it.svg#proxima_novaitalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('mark_simonson_-_proxima_nova.eot');
  src: url('mark_simonson_-_proxima_nova.eot?#iefix')
      format('embedded-opentype'),
    url('mark_simonson_-_proxima_nova.woff2') format('woff2'),
    url('mark_simonson_-_proxima_nova.woff') format('woff'),
    url('mark_simonson_-_proxima_nova.svg#proxima_novaregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova Alt';
  src: url('mark_simonson_-_proxima_nova_a_bold_it.eot');
  src: url('mark_simonson_-_proxima_nova_a_bold_it.eot?#iefix')
      format('embedded-opentype'),
    url('mark_simonson_-_proxima_nova_a_bold_it.woff2') format('woff2'),
    url('mark_simonson_-_proxima_nova_a_bold_it.woff') format('woff'),
    url('mark_simonson_-_proxima_nova_a_bold_it.svg#proxima_nova_abold_italic')
      format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'ProximaNova Alt';
  src: url('mark_simonson_-_proxima_nova_a_bold.eot');
  src: url('mark_simonson_-_proxima_nova_a_bold.eot?#iefix')
      format('embedded-opentype'),
    url('mark_simonson_-_proxima_nova_a_bold.woff2') format('woff2'),
    url('mark_simonson_-_proxima_nova_a_bold.woff') format('woff'),
    url('mark_simonson_-_proxima_nova_a_bold.svg#proxima_nova_abold')
      format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova Alt';
  src: url('mark_simonson_-_proxima_nova_a_it.eot');
  src: url('mark_simonson_-_proxima_nova_a_it.eot?#iefix')
      format('embedded-opentype'),
    url('mark_simonson_-_proxima_nova_a_it.woff2') format('woff2'),
    url('mark_simonson_-_proxima_nova_a_it.woff') format('woff'),
    url('mark_simonson_-_proxima_nova_a_it.svg#proxima_nova_aitalic')
      format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'ProximaNova Alt';
  src: url('mark_simonson_-_proxima_nova_a.eot');
  src: url('mark_simonson_-_proxima_nova_a.eot?#iefix')
      format('embedded-opentype'),
    url('mark_simonson_-_proxima_nova_a.woff2') format('woff2'),
    url('mark_simonson_-_proxima_nova_a.woff') format('woff'),
    url('mark_simonson_-_proxima_nova_a.svg#proxima_nova_aregular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova Soft';
  src: url('mark_simonson_-_proxima_nova_s_bold_it.eot');
  src: url('mark_simonson_-_proxima_nova_s_bold_it.eot?#iefix')
      format('embedded-opentype'),
    url('mark_simonson_-_proxima_nova_s_bold_it.woff2') format('woff2'),
    url('mark_simonson_-_proxima_nova_s_bold_it.woff') format('woff'),
    url('mark_simonson_-_proxima_nova_s_bold_it.svg#proxima_nova_sbold_italic')
      format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'ProximaNova Soft';
  src: url('mark_simonson_-_proxima_nova_s_bold.eot');
  src: url('mark_simonson_-_proxima_nova_s_bold.eot?#iefix')
      format('embedded-opentype'),
    url('mark_simonson_-_proxima_nova_s_bold.woff2') format('woff2'),
    url('mark_simonson_-_proxima_nova_s_bold.woff') format('woff'),
    url('mark_simonson_-_proxima_nova_s_bold.svg#proxima_nova_sbold')
      format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova Soft';
  src: url('mark_simonson_-_proxima_nova_s_it.eot');
  src: url('mark_simonson_-_proxima_nova_s_it.eot?#iefix')
      format('embedded-opentype'),
    url('mark_simonson_-_proxima_nova_s_it.woff2') format('woff2'),
    url('mark_simonson_-_proxima_nova_s_it.woff') format('woff'),
    url('mark_simonson_-_proxima_nova_s_it.svg#proxima_nova_sitalic')
      format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'ProximaNova Soft';
  src: url('mark_simonson_-_proxima_nova_s.eot');
  src: url('mark_simonson_-_proxima_nova_s.eot?#iefix')
      format('embedded-opentype'),
    url('mark_simonson_-_proxima_nova_s.woff2') format('woff2'),
    url('mark_simonson_-_proxima_nova_s.woff') format('woff'),
    url('mark_simonson_-_proxima_nova_s.svg#proxima_nova_sregular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
