.form-control {
  border-radius: $s10px;
  background-color: rgba(255, 255, 154, 0.321);
}
.ui.search.dropdown > input.search {
  background-color: rgba(255, 255, 154, 0.321) !important;
  border-radius: $s10px;
}
.ui.selection.dropdown {
  border-radius: $s10px;
}
.ui.search.dropdown .menu {
  max-height: 10rem;
}
.ui.selection.dropdown .menu {
  border-radius: 0 0 $s10px $s10px;
}
.vue-switcher {
  margin-top: 0.5rem;
}
.vue-switcher__label {
  display: block;
  font-size: $s16px !important;
  margin-bottom: 0.5rem !important;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-color: rgba($color: #f44336, $alpha: 0.321);
}
.vdp-datepicker .is-invalid > .form-control {
  background-color: rgba($color: #f44336, $alpha: 0.321);
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  word-break: normal;
}
.selectdiv:after {
  content: '\e9c1';
  font: normal normal normal 17px/1 icomoon;
  color: #50b848;
  right: 11px;
  top: 6px;
  padding: 5px 5px 0px 6px;
  position: absolute;
  pointer-events: none;
}
