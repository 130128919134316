@import '~vue-search-select/dist/VueSearchSelect.css';
@import './scss/ProximaNova/WEB/proxima-nova';
@import './scss/variables';
@import './scss/custom/size';
@import './scss/custom/colors';
@import './scss/assets/css/bootstrap';
@import './scss/assets/css/bootstrap_limitless';
@import './scss/assets/css/layout';
@import './scss/assets/css/components';
@import './scss/assets/css/colors';
@import './scss/global_assets/css/icons/icomoon/styles.min.css';
@import './scss/global_assets/css/icons/fontawesome/styles.min.css';
@import './scss/custom/body';
@import './scss/custom/card';
@import './scss/custom/tabs';
@import './scss/custom/dropdown-menu';
@import './scss/custom/form';
@import './scss/custom/sidebar';
@import '~vue-loading-overlay/dist/vue-loading.css';
@import '~pretty-checkbox/src/pretty-checkbox.scss';

@media only screen and (max-width: 1440px) {
	body {
		font-size: 0.875rem;
	}
	.fa-2x {
		font-size: 1.3rem;
	}
	h4,
	.h4 {
		font-size: 1rem;
	}
}
dt,
dd,
.media-title,
.table thead th {
	white-space: nowrap;
}
.media-chat-item:before {
	content: '';
	left: -5px;
	top: 0.93752rem;
	position: absolute;
	margin-left: 0;
	border-top: 5px solid transparent;
	border-right: 5px solid;
	border-right-color: inherit;
	border-bottom: 5px solid transparent;
}
.bg-green {
	&.media-chat-item:before {
		border-right-color: #8bc34a;
	}
}
.bg-orange {
	&.media-chat-item:before {
		border-right-color: #ff9800;
	}
}
.bg-danger-400 {
	&.media-chat-item:before {
		border-right-color: #ef5350;
	}
}
.arrow-right {
	margin-right: 38px !important;
	background-color: #fff;
}
.arrow-right:before {
	content: '';
	right: -38px;
	top: 0rem;
	position: absolute;
	margin-right: 0;
	border-color: transparent #fff;
	border-style: solid;
	border-width: 44px 0px 44px 44px;
	height: 0px;
	width: 0px;
}
.w100px {
	width: 6.25rem;
}

.login-bg {
	background-image: url('./scss/global_assets/images/bg-login.png');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 90% 90%;
	padding-top:1rem;
}
.login-form{
	width:34.375rem;
	> .card {
		width:calc(34.375rem - 3rem);
		padding:3rem;
		background:transparent;
		box-shadow:none;
		>.card-body{
			border-radius:1rem;

		}
	}
}
.btn-pill {
	border-radius:1rem !important;
}
.page-content-login{
	background:rgba(35, 42, 52,1);
	// background-image: linear-gradient(127deg, #22262d, #304456 124%);
}
.radius-5{
	border-radius:5px;
}
.btn-icon-rounded {
	padding: 0.62503rem;
	border-radius: 100px;
}
select.form-control {
	/* for Firefox */
	-moz-appearance: none;
	/* for Chrome */
	-webkit-appearance: none;
}

.table th,
.table td {
	padding: 0.5rem 1rem;
}
.breadcrumb-line-component{
	border-radius: $s10px;
}