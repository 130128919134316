.nav-tabs .nav-link {
  border-top-left-radius: $s10px;
  border-top-right-radius: $s10px;
  color: rgba($color: #000000, $alpha: 0.2);
  font-weight: 700;
  background-color: #fff;
  padding-top: $s13px;
  padding-bottom: $s13px;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #50b848;
  border-color: #33862d #33862d #246320;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  color: #ffffff;
  background-color: #50b848;
  border-color: #33862d #33862d #246320;
}
.nav-tabs .nav-item:not(:first-child) {
  margin-left: $s10px;
}
.nav-tabs {
  border-bottom: none;
}
