.card {
	border-radius: $s10px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	border: transparent;
}
.card > .table:last-child,
.card > [class*='table-responsive']:last-child > .table:last-child {
	border-bottom-right-radius: $s10px;
	border-bottom-left-radius: $s10px;
}
.card > .table:first-child,
.card > [class*='table-responsive']:first-child > .table:first-child {
	border-top-left-radius: $s10px;
	border-top-right-radius: $s10px;
}
.card > .table:last-child tbody:last-child tr:last-child,
.card > .table:last-child tfoot:last-child tr:last-child,
.card
> [class*='table-responsive']:last-child
> .table:last-child
tbody:last-child
tr:last-child,
.card
> [class*='table-responsive']:last-child
> .table:last-child
tfoot:last-child
tr:last-child {
	border-bottom-left-radius: $s10px;
	border-bottom-right-radius: $s10px;
}
.card > .table:first-child thead:first-child tr:first-child td:first-child,
.card > .table:first-child thead:first-child tr:first-child th:first-child,
.card > .table:first-child tbody:first-child tr:first-child td:first-child,
.card > .table:first-child tbody:first-child tr:first-child th:first-child,
.card
> [class*='table-responsive']:first-child
> .table:first-child
thead:first-child
tr:first-child
td:first-child,
.card
> [class*='table-responsive']:first-child
> .table:first-child
thead:first-child
tr:first-child
th:first-child,
.card
> [class*='table-responsive']:first-child
> .table:first-child
tbody:first-child
tr:first-child
td:first-child,
.card
> [class*='table-responsive']:first-child
> .table:first-child
tbody:first-child
tr:first-child
th:first-child {
	border-top-left-radius: $s10px;
}
.card-header:first-child {
	border-radius: $s10px $s10px 0 0;
}
.card-footer:last-child {
	border-radius: 0 0 $s10px $s10px;
}
.card-img-top {
	width: 100%;
	border-top-left-radius: $s10px;
	border-top-right-radius: $s10px;
}
