body {
	background-color: $green-05;
	font-size: $s13px;
	font-family: ProximaNova, -apple-system, BlinkMacSystemFont, 'Segoe UI',
	Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.content {
	min-height: 100vh;
}
.vdp-datepicker__calendar {
	border-radius: $s10px;
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
	border: 1px solid #4bd;
	border-radius: $s10px;
}
.vdp-datepicker__calendar header .prev:not(.disabled):hover,
.vdp-datepicker__calendar header .next:not(.disabled):hover,
.vdp-datepicker__calendar header .up:not(.disabled):hover {
	background: #eee;
	border-radius: $s10px;
}
.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: rgba($color: #000000, $alpha: 0.5);
}
.modal-content {
	border-radius: $s10px;
}
.modal-header {
	position: relative;
	border-top-left-radius: $s10px;
	border-top-right-radius: $s10px;
}
.modal-footer {
	border-bottom-right-radius: $s10px;
	border-bottom-left-radius: $s10px;
}
.swal-modal {
	border-radius: $s10px !important;
}
.rounded-15 {
	border-radius: $s15px !important;
}
.nav-item {
	> .navbar-nav-link {
		border-bottom: 2px solid transparent;
		&.router-link-exact-active.router-link-active.active {
			border-bottom: 2px solid #50b848;
		}
	}
}
.media-chat-item {
	border-radius: $s10px;
}
